/**
 * @generated SignedSource<<7271b256eb40ef50954f733c7329718c>>
 * @relayHash e92722c8d319251ecd6db87e522c405b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/476.0.0-2024-08-20T10:14:05.292Z/SearchBarRefetchQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBarRefetchQuery$variables = {
  fetchUserRecentHistoryItem?: boolean | null;
  hasQuery: boolean;
  hasUserId: boolean;
  includeTypes?: ReadonlyArray<string | null> | null;
  localRecentHistoryItemIds?: ReadonlyArray<string | null> | null;
  query: string;
  userId: string;
};
export type SearchBarRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"SearchBar_viewer">;
  };
};
export type SearchBarRefetchQuery = {
  response: SearchBarRefetchQuery$data;
  variables: SearchBarRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "fetchUserRecentHistoryItem"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasQuery"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v3 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "includeTypes"
},
v4 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "localRecentHistoryItemIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v7 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v8 = [
  {
    "kind": "Variable",
    "name": "excludeItemPks",
    "variableName": "localRecentHistoryItemIds"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 12
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchBarRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "hasQuery",
                "variableName": "hasQuery"
              },
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "SearchBar_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "SearchBarRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "isPopularSearchesEnabledByLocale",
            "args": [
              {
                "kind": "Literal",
                "name": "featureName",
                "value": "POPULAR_SEARCHES"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabledByLocale",
            "storageKey": "isEnabledByLocale(featureName:\"POPULAR_SEARCHES\")"
          },
          {
            "condition": "hasQuery",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "includeTypes",
                    "variableName": "includeTypes"
                  },
                  (v7/*: any*/)
                ],
                "concreteType": "SearchSuggestion",
                "kind": "LinkedField",
                "name": "searchSuggestions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchSuggestionList",
                    "kind": "LinkedField",
                    "name": "suggestions",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchSuggestionTerm",
                        "kind": "LinkedField",
                        "name": "terms",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "term",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "urlLabel",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "userId",
                    "variableName": "userId"
                  }
                ],
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "if": null,
                    "kind": "Defer",
                    "label": "SearchBar_viewer$defer$getRecentHistory_user",
                    "selections": [
                      {
                        "condition": "fetchUserRecentHistoryItem",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v8/*: any*/),
                            "concreteType": "RecentHistoryItemsConnection",
                            "kind": "LinkedField",
                            "name": "recentHistoryItems",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RecentHistoryItemsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Item",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "contemporaryTrackingString",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": [
                                          {
                                            "kind": "Literal",
                                            "name": "size",
                                            "value": "small"
                                          }
                                        ],
                                        "kind": "ScalarField",
                                        "name": "firstPhotoWebPath",
                                        "storageKey": "firstPhotoWebPath(size:\"small\")"
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "pdpURL",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "browseUrl",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "categoryCode",
                                        "storageKey": null
                                      },
                                      (v9/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "categoryPath",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Seller",
                                        "kind": "LinkedField",
                                        "name": "seller",
                                        "plural": false,
                                        "selections": [
                                          (v9/*: any*/),
                                          (v10/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v10/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfo",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v8/*: any*/),
                            "filters": [
                              "excludeItemPks"
                            ],
                            "handle": "connection",
                            "key": "getRecentHistory_recentHistoryItems",
                            "kind": "LinkedHandle",
                            "name": "recentHistoryItems"
                          }
                        ]
                      }
                    ]
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/476.0.0-2024-08-20T10:14:05.292Z/SearchBarRefetchQuery",
    "metadata": {},
    "name": "SearchBarRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "77af685778ace754c20152f00c0ac153";

export default node;
