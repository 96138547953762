// Registration trigger values.
const AUTO_MODAL = 'AUTO_MODAL';
const CART = 'CART';
const CHECKOUT = 'CHECKOUT';
const CONTACT_SELLER = 'CONTACT_SELLER';
const REGISTRATION_LINK = 'REGISTRATION_LINK';
const REGISTRATION_LINK_NAVIGATION = 'REGISTRATION_LINK_NAVIGATION';
const SAVE_ITEM = 'SAVE_ITEM';
const SAVE_SEARCH = 'SAVE_SEARCH';
const TRADE = 'TRADE';
const NOT_SET = 'NOT_SET';

// Map ga label to a registration trigger value.
const REGISTRATION_TRIGGER_MAP: { [key: string]: string } = {
    'abandoned checkout': AUTO_MODAL,
    'pageview limit reached': AUTO_MODAL,
    'exit pdp': AUTO_MODAL,
    'same pdp view': AUTO_MODAL,
    'unauth page': AUTO_MODAL,
    'google one tap': AUTO_MODAL,
    'channel prompt': AUTO_MODAL,
    'cart link': CART,
    purchase: CHECKOUT,
    'make offer': CHECKOUT,
    'complete account modal': CHECKOUT,
    'contact link in pdp - sidebar': CONTACT_SELLER,
    'contact link in pdp - more from dealer module': CONTACT_SELLER,
    'pdp chat box': CONTACT_SELLER,
    'request price button': CONTACT_SELLER,
    'pdp image carousel': CONTACT_SELLER,
    'image zoom modal': CONTACT_SELLER,
    'in dealer storefront - button': CONTACT_SELLER,
    'in dealer storefront - call button': CONTACT_SELLER,
    'make offer exit': CONTACT_SELLER,
    'contact link in pdp - request customization': CONTACT_SELLER,
    'call seller link': CONTACT_SELLER,
    'login link - global header': REGISTRATION_LINK,
    'register query param - address bar': REGISTRATION_LINK,
    'login query param - address bar': REGISTRATION_LINK,
    'register link': REGISTRATION_LINK,
    'register link - global header': REGISTRATION_LINK_NAVIGATION,
    'footer navigation': REGISTRATION_LINK_NAVIGATION,
    'save item - product page': SAVE_ITEM,
    'save item - search/browse': SAVE_ITEM,
    'save search - sold pdp': SAVE_ITEM,
    'save item - product nav': SAVE_ITEM,
    'save item - favorites page': SAVE_ITEM,
    'save item - product page - more from dealer': SAVE_ITEM,
    'save item - similar items page': SAVE_ITEM,
    'save item - auction PDP': SAVE_ITEM,
    'save item - product page - you may also like': SAVE_ITEM,
    'save item - product page - top you may also like': SAVE_ITEM,
    'save item - dealer storefront page': SAVE_ITEM,
    'search banner': SAVE_SEARCH,
    'save search - search/browse page': SAVE_SEARCH,
    'save search - dealer storefront page': SAVE_SEARCH,
    'save search - unlisted pdp': SAVE_SEARCH,
    'save search - design series': SAVE_SEARCH,
    'trade application page': TRADE,
    'trade application more info': TRADE,
    'third pageview trade login': TRADE,
};

export const getRegistrationTriggerValue = ({ ga } = { ga: {} }): string => {
    const { label }: { label?: string } = ga || {};

    if (label) {
        return REGISTRATION_TRIGGER_MAP[label] || NOT_SET;
    }

    return NOT_SET;
};
