import injectScript from './utils/injectScript';
import { getSocialNetwork } from './utils/getSocialNetwork';
import { loadScript } from './utils/loadScript';
import { getFeatureFlags } from './utils/getFeatureFlags';
import { APPLE_CLIENT_ID, ERROR_MESSAGES, SOCIAL_NETWORK_NAMES } from './constants';
import { LoadPayload, WidgetParams } from './types';
import serverVars from 'server-vars';

type LoaderScripts = {
    [key in LoadPayload]: (options?: WidgetParams) => Promise<void>;
};

const scripts: LoaderScripts = {
    async pinterest(options) {
        if (!options || !options.relayEnvironment) {
            throw new Error(ERROR_MESSAGES.PINTEREST_OPTIONS_NOT_PROVIDED);
        }
        const pinterestApp = await getSocialNetwork.byName('PINTEREST', options.relayEnvironment);
        if (!pinterestApp) {
            throw new Error(ERROR_MESSAGES.PINTEREST_APP_NOT_AVAILABLE);
        }
        await injectScript('//assets.pinterest.com/sdk/sdk.js');
    },

    async facebook(options) {
        if (!options || !options.relayEnvironment) {
            throw new Error(ERROR_MESSAGES.FACEBOOK_OPTIONS_NOT_PROVIDED);
        }
        const locale = options.locale || 'en-US';
        const formattedLocale = locale.replace('-', '_'); // locale must be snake_case
        const facebookApp = await getSocialNetwork.byName(
            SOCIAL_NETWORK_NAMES.FACEBOOK,
            options.relayEnvironment
        );
        const appId = facebookApp?.parameters?.clientId;

        if (!facebookApp) {
            throw new Error(ERROR_MESSAGES.FACEBOOK_APP_NOT_AVAILABLE);
        }
        try {
            await injectScript(`//connect.facebook.net/${formattedLocale}/sdk.js`, {
                params: {
                    id: 'facebook-jssdk',
                },
            });
        } catch (err) {
            throw new Error(
                `${SOCIAL_NETWORK_NAMES.FACEBOOK} ${ERROR_MESSAGES.SOCIAL_NETWORK_SDK_LOADING_FAILED}`
            );
        }
        if (!appId) {
            throw new Error(ERROR_MESSAGES.MISSING_SOCIAL_NETWORK_PARAMETERS);
        }

        window.fbAsyncInit = () => {
            window.FB.init({
                appId,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v14.0',
            });
            window.FB.AppEvents.logPageView();
        };
    },

    async google(options) {
        if (!options || !options.relayEnvironment) {
            throw new Error(ERROR_MESSAGES.GOOGLE_OPTIONS_NOT_PROVIDED);
        }
        const googleApp = await getSocialNetwork.byName(
            SOCIAL_NETWORK_NAMES.GOOGLE,
            options.relayEnvironment
        );
        if (!googleApp) {
            throw new Error(ERROR_MESSAGES.GOOGLE_APP_NOT_AVAILABLE);
        }
        if (!googleApp.parameters || !googleApp.parameters.clientId) {
            throw new Error(ERROR_MESSAGES.MISSING_SOCIAL_NETWORK_PARAMETERS);
        }
        try {
            await injectScript('//accounts.google.com/gsi/client');
        } catch (err) {
            throw new Error(
                `${SOCIAL_NETWORK_NAMES.GOOGLE} ${ERROR_MESSAGES.SOCIAL_NETWORK_SDK_LOADING_FAILED}`
            );
        }

        const { googleOneTapAutoLogin } =
            (await getFeatureFlags({
                environment: options.relayEnvironment,
            })) || {};

        window.google.accounts.id.initialize({
            client_id: googleApp.parameters.clientId,
            cancel_on_tap_outside: false,
            // with auto_select enabled FedCM API has a 10 minute quiet period after an auto-reauthentication.
            auto_select: !!googleOneTapAutoLogin,
            // @ts-ignore temp prop is used to enable FedCM prior to becoming mandatory for all sign-ins
            // https://developers.google.com/identity/gsi/web/guides/fedcm-migration
            use_fedcm_for_prompt: true,
            callback: response => window.dibsGoogleCallback(response),
        });
    },

    async apple(options) {
        if (!options || !options.relayEnvironment) {
            throw new Error(ERROR_MESSAGES.APPLE_OPTIONS_NOT_PROVIDED);
        }
        const appleApp = await getSocialNetwork.byName(
            SOCIAL_NETWORK_NAMES.APPLE,
            options.relayEnvironment
        );
        if (!appleApp) {
            throw new Error(ERROR_MESSAGES.APPLE_APP_NOT_AVAILABLE);
        }
        try {
            await injectScript(
                '//appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
            );
        } catch (err) {
            throw new Error(
                `${SOCIAL_NETWORK_NAMES.APPLE} ${ERROR_MESSAGES.SOCIAL_NETWORK_SDK_LOADING_FAILED}`
            );
        }
        window.AppleID.auth.init({
            clientId: APPLE_CLIENT_ID,
            scope: 'email name',
            redirectURI: `${serverVars.get('localeOrigin')}/signinwithapple/dibs-post`,
            state: '',
            usePopup: true,
        });
    },

    async recaptcha() {
        await injectScript('//www.google.com/recaptcha/api.js?render=explicit');
    },

    async recaptchaEnterprise({ recaptchaV3SiteKey } = {}) {
        await injectScript(`//www.google.com/recaptcha/enterprise.js?render=${recaptchaV3SiteKey}`);
    },

    async klarnaPayments() {
        await injectScript('https://x.klarnacdn.net/kp/lib/v1/api.js');
    },
};

const cachedLoaders: Partial<Record<keyof LoaderScripts, Promise<void>>> = {};

const widgetLoader = {
    load: (type: keyof LoaderScripts, options?: WidgetParams): Promise<void> =>
        loadScript({ type, options, cachedLoaders, scripts }),
};

export default widgetLoader;

export * from './types';
