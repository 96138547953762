import { useCallback, MouseEventHandler, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { graphql, useFragment, useMutation } from 'react-relay';
import { setMultiCartItem } from '../actions/cartActions';

import type { useAddItemToCart_item$key } from './__generated__/useAddItemToCart_item.graphql';
import type { useAddItemToCart_user$key } from './__generated__/useAddItemToCart_user.graphql';

const userFragment = graphql`
    fragment useAddItemToCart_user on User {
        serviceId
    }
`;

const itemFragment = graphql`
    fragment useAddItemToCart_item on Item {
        serviceId
        defaultSkuId @required(action: LOG)
    }
`;

export const userCartItemAddMutation = graphql`
    mutation useAddItemToCart_userCartItemAddMutation($input: UserCartItemAddOrUpdateInput!) {
        userCartItemAddOrUpdate(input: $input) {
            clientMutationId
        }
    }
`;

export const useAddItemToCart = ({
    user: userRef,
    item: itemRef,
    skuId,
    quantitySelected,
    onClick,
}: {
    user: useAddItemToCart_user$key | null | undefined;
    item: useAddItemToCart_item$key;
    skuId: string | null;
    quantitySelected: number;
    onClick: MouseEventHandler;
}): {
    addItemToCart: (event: MouseEvent<HTMLElement>) => void;
    isAddingItemToCart: boolean;
} => {
    const dispatch = useDispatch();
    const item = useFragment(itemFragment, itemRef);
    const user = useFragment(userFragment, userRef);
    const userId = user?.serviceId;
    const { serviceId: itemId, defaultSkuId } = item || {};

    const [commitUserCartItemAdd, userCartItemAddInFlight] = useMutation(userCartItemAddMutation);
    const addItemToCart = useCallback(
        async (event: MouseEvent<HTMLElement>) => {
            const sharedParams = {
                skuId: skuId || defaultSkuId,
                quantity: quantitySelected || 1,
                type: 'CHECKOUT',
            } as const;
            const skuIdParam = skuId || defaultSkuId;
            if (userId) {
                await commitUserCartItemAdd({
                    variables: {
                        input: {
                            itemId,
                            ...sharedParams,
                        },
                    },
                });
                onClick(event);
            } else if (!userId && itemId && skuIdParam) {
                dispatch(
                    setMultiCartItem({
                        id: itemId,
                        ...sharedParams,
                        skuId: skuIdParam,
                    })
                );
                onClick(event);
            }
        },
        [
            userId,
            itemId,
            skuId,
            quantitySelected,
            onClick,
            commitUserCartItemAdd,
            defaultSkuId,
            dispatch,
        ]
    );

    return {
        addItemToCart,
        isAddingItemToCart: userCartItemAddInFlight,
    };
};
